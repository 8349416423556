<template>
  <ValidationObserver slim ref="observer" v-slot="{ valid }">
    <EuiContainer style="height: calc(100vh - 180px);">
      <add-users-dialog
        @updateUsers="updateUsers"
        @closeDialog="isAddUsersDialogOpen = false"
        :open="true"
        v-if="isAddUsersDialogOpen"/>
      <!-- **TOOLBAR** -->
      <form id="createGroup" @submit.prevent="createGroup">
        <EuiGrid gutters>
          <EuiGridRow>
            <EuiGridColumn>
              <div class="flex items-center">
                <EuiButton iconOnly size="large" variant="text" @click.prevent="goBack()">
                  <EuiIcon name="chevron_left" color="primary"/>
                </EuiButton>
                <EuiHeading :level="1" bold>{{ $t('settings.groups.create.title') }}</EuiHeading>
              </div>
            </EuiGridColumn>
          </EuiGridRow>
          <EuiGridRow>
            <EuiGridColumn width="1/3">
              <EuiBlock :title="$i18n.t('settings.users.create.groupInfo.title')">
                <ValidationProvider :name="$t('settings.users.create.name.label')" rules="required" v-slot="{ errors, valid, invalid }">
                  <EuiTextField adaptSize
                                class="mb-2"
                                :label="$t('settings.users.create.name.label')"
                                type="text"
                                v-model="group.name"
                                :valid="valid"
                                :invalid="errors.length > 0"
                                :warning="invalid && errors.length === 0"
                                :errorMessage="errors[0]"/>
                </ValidationProvider>
              </EuiBlock>
            </EuiGridColumn>
          </EuiGridRow>
          <EuiGridRow>
            <EuiGridColumn>
              <EuiBlock :title="$i18n.t('settings.groups.create.addUsers.title')">
                <div v-if="users.length > 0 || searchInput.length > 0">
                  <EuiGrid>
                    <EuiGridRow>
                      <EuiGridColumn>
                        <div>
                          <span class="font-bold">
                            <template v-if="selected.length">
                              {{ $tc('settings.groups.create.addUsers.label.selectedUsers', selected.length) }}
                            </template>
                            <template v-else>
                              {{ $tc('settings.groups.create.addUsers.label.nbUsers', users.length) }}
                            </template>
                          </span>
                          <EuiButton variant="text" :style="{visibility: selected.length ? 'visible' : 'hidden'}" color="primary" @click.prevent="deleteSelectedUsers">
                            {{ $t('settings.users.button.delete') }}
                          </EuiButton>
                        </div>
                      </EuiGridColumn>
                      <EuiGridColumn width="1/3">
                        <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                                  v-model.trim="searchInput"
                                  @keydown.enter.prevent="searchUser()">
                          <template #endEnhancer>
                            <div class="eui-m-cardFilter__clear">
                              <template v-if="searchInput && searchInput.length > 0">
                                <EuiButton iconOnly size="small" color="primary" @click.prevent.stop="resetSearchInput">
                                  <EuiIcon name="close"/>
                                </EuiButton>
                                <div class="eui-m-cardFilter__separator"></div>
                              </template>
                            </div>
                            <EuiButton iconOnly color="primary" size="small" @click.prevent.stop="searchUser()">
                              <EuiIcon name="search"/>
                            </EuiButton>
                          </template>
                        </EuiInput>
                      </EuiGridColumn>
                    </EuiGridRow>
                  </EuiGrid>
                  <EuiTable>
                    <EuiTableHeader>
                      <EuiTableRow>
                        <EuiTableHead dataType="input">
                          <EuiCheckbox v-model="selectAllUsers"/>
                        </EuiTableHead>
                        <EuiTableHead v-bind:col="'fullname'"
                                       v-bind:rows="users">{{ $t('settings.groups.create.addUsers.table.headers.name') }}</EuiTableHead>
                        <EuiTableHead v-bind:col="'email'"
                                       v-bind:rows="users">{{ $t('settings.groups.create.addUsers.table.headers.email') }}</EuiTableHead>
                      </EuiTableRow>
                    </EuiTableHeader>
                    <EuiTableBody>
                      <EuiTableRow v-for="(item, index) in filteredUsers" :key="index">
                        <EuiTableCell dataType="input" @click.native.stop="">
                          <EuiCheckbox :value="item" v-model="selected" />
                        </EuiTableCell>
                        <EuiTableCell>{{ item.fullname }}</EuiTableCell>
                        <EuiTableCell>{{ item.email }}</EuiTableCell>
                        <!-- <EuiTableCell dataType="button" @click.native.stop>
                          <EuiButton :style="{visibility: selected.length === 0 ? 'visible' : 'hidden'}" @click.native="deleteUser(item)">
                            {{ $t('settings.users.button.delete') }}
                          </EuiButton>
                        </EuiTableCell>-->
                      </EuiTableRow>
                    </EuiTableBody>
                  </EuiTable>
                </div>
                <template v-else>
                  <p class="eui-u-mb-4 eui-u-mt-0 eui-u-text-base eui-u-font-bold eui-u-text-blue-grey">{{ $t('settings.groups.create.addUsers.table.noData') }}</p>
                  <EuiButton variant="raised" color="primary" size="small" @click.prevent="openDialog">{{ $t('button.add') }}</EuiButton>
                </template>
              </EuiBlock>
            </EuiGridColumn>
          </EuiGridRow>
          <EuiGridRow>
            <EuiGridColumn>
              <EuiBlock :title="$i18n.t('settings.groups.create.userPermissions.title')">
                <div>
                  <EuiCheckbox class="mr-2" v-model="selectAll">
                    {{ $t('settings.groups.create.allRights.label') }}
                  </EuiCheckbox>
                </div>
                <EuiTable>
                  <EuiTableHeader>
                    <EuiTableRow>
                      <EuiTableHead> </EuiTableHead>
                      <EuiTableHead>{{ $t('settings.groups.create.permissions.table.headers.createUpdate') }}</EuiTableHead>
                      <EuiTableHead>{{ $t('settings.groups.create.permissions.table.headers.list') }}</EuiTableHead>
                      <EuiTableHead>{{ $t('settings.groups.create.permissions.table.headers.other') }}</EuiTableHead>
                    </EuiTableRow>
                  </EuiTableHeader>
                  <EuiTableBody>
                    <EuiTableRow>
                      <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.create.permissions.table.headers.paraphers') }}</EuiTableHead>
                    </EuiTableRow>
                    <EuiTableRow>
                      <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.templates') }}</EuiTableCell>
                      <EuiTableCell>
                        <EuiCheckbox v-model="group.hasWriteModelPermission"/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiCheckbox v-model="group.hasReadModelPermission"/>
                      </EuiTableCell>
                      <EuiTableCell>
                      </EuiTableCell>
                    </EuiTableRow>
                    <EuiTableRow>
                      <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.sharedTemplates') }}</EuiTableCell>
                      <EuiTableCell>
                        <EuiCheckbox v-model="group.hasWholeSignbooksEditingPermission"/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiCheckbox v-model="group.hasWholeSignbooksListingPermission"/>
                      </EuiTableCell>
                      <EuiTableCell>
                      </EuiTableCell>
                    </EuiTableRow>
                    <EuiTableRow>
                      <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.create.permissions.table.headers.users') }}</EuiTableHead>
                    </EuiTableRow>
                    <EuiTableRow>
                      <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.userRights') }}</EuiTableCell>
                      <EuiTableCell>
                        <EuiCheckbox v-model="group.hasEditUserPermission"/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiCheckbox checked disabled/>
                      </EuiTableCell>
                      <EuiTableCell>
                        <EuiCheckbox v-model="group.hasAccessRulesPermission">
                          {{ $t('settings.groups.create.permissions.table.other.managePermissions') }}
                        </EuiCheckbox>
                      </EuiTableCell>
                    </EuiTableRow>
                    <EuiTableRow>
                      <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.manageSocialSignatureAuthorizations') }}</EuiTableCell>
                      <EuiTableCell class="table-cell">
                      </EuiTableCell>
                      <EuiTableCell class="table-cell">
                      </EuiTableCell>
                      <EuiTableCell class="table-cell">
                        <EuiCheckbox v-model="group.hasSocialSignerTriggerPermission">
                          {{ $t('settings.groups.create.permissions.table.other.manageSocialSignatureAuthorizations') }}
                        </EuiCheckbox>
                      </EuiTableCell>
                    </EuiTableRow>
                  </EuiTableBody>
                </EuiTable>
              </EuiBlock>
            </EuiGridColumn>
          </EuiGridRow>
        </EuiGrid>
       </form>
    </EuiContainer>
    <EuiBottomBar>
      <EuiButton  @click.prevent="goBack">{{ $t('button.cancel') }}</EuiButton>
      <EuiButton variant="raised" color="primary" type="submit" form="createGroup" :disabled="!valid">{{ $t('button.save') }}</EuiButton>
    </EuiBottomBar>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import DOMpurify from 'dompurify';

export default {
  /**
   * The name of the page.
   */
  name: 'CreateGroup',
  components: {
    addUsersDialog: () => import(/* webpackPrefetch: true */ '@/pages/settings/dialog/addUsers'),
  },
  data() {
    return {
      isAddUsersDialogOpen: false,
      selected: [],
      searchInput: '',
      searchParams: {},
      users: [],
      filteredUsers: [],
      group: {
        name: '',
        hasReadModelPermission: false,
        hasWriteModelPermission: false,
        hasWholeSignbooksEditingPermission: false,
        hasWholeSignbooksListingPermission: false,
        hasEditUserPermission: false,
        hasAccessRulesPermission: false,
        hasSocialSignerTriggerPermission: false,
        users: [],
      },
    };
  },
  /**
   * The computed properties for the component.
   */
  computed: {
    selectAll: {
      get() {
        return this.group.hasReadModelPermission &&
          this.group.hasWriteModelPermission &&
          this.group.hasWholeSignbooksEditingPermission &&
          this.group.hasWholeSignbooksListingPermission &&
          this.group.hasEditUserPermission &&
          this.group.hasAccessRulesPermission &&
          this.group.hasSocialSignerTriggerPermission;
      },
      set(value) {
        this.group.hasReadModelPermission = value;
        this.group.hasWriteModelPermission = value;
        this.group.hasWholeSignbooksEditingPermission = value;
        this.group.hasWholeSignbooksListingPermission = value;
        this.group.hasEditUserPermission = value;
        this.group.hasAccessRulesPermission = value;
        this.group.hasSocialSignerTriggerPermission = value;
      },
    },
    selectAllUsers: {
      get() {
        return this.users ? this.selected.length === this.users.length : false;
      },
      set(value) {
        const selected = [];
        if (value) {
          this.users.forEach((user) => {
            selected.push(user);
          });
        }
        this.selected = selected;
      },
    },
  },
  watch: {
    users(value) {
      this.filteredUsers = value;
    },
  },
  methods: {
    ...mapActions({
      create: 'ModuleEdocSign/settingsGroups/create',
    }),
    filterUser(array, string) {
      return array.filter((o) => Object.keys(o).some((k) => {
        if (o[k] && !Array.isArray(o[k])) {
          return o[k].toLowerCase().includes(string.toLowerCase());
        }
      }));
    },
    searchUser() {
      if (this.searchInput) {
        this.filteredUsers = this.filterUser(this.users, this.searchInput);
      }
    },
    resetSearchInput() {
      this.filteredUsers = this.users;
      this.searchInput = '';
    },
    deleteSelectedUsers() {
      if (this.selectAllUsers) {
        this.users = [];
        this.selected = [];
      } else {
        this.users = this.users.filter((el) => !this.selected.includes(el));
        this.selected = [];
      }
    },
    deleteUser(item) {
      this.users.splice(this.users.indexOf(item), 1);
    },
    updateUsers(users) {
      this.users = users;
      this.filteredUsers = users;
      this.isAddUsersDialogOpen = false;
    },
    openDialog() {
      this.isAddUsersDialogOpen = true;
    },
    goBack() {
      this.$router.push({ name: 'settings.groups.index' });
    },
    createGroup() {
      this.group.name = DOMpurify.sanitize(this.group.name);
      this.users.forEach((item) => this.group.users.push({ userId: item.userId }));
      this.create(this.group).then(() => {
        this.goBack();
      });
    },
  },
};
</script>
